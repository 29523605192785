@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";
.cardsContainer {
  @include flex_center(row wrap);
  margin: 6rem 1rem;
    @include mediaQuery-1024(){
      padding: 0 80px;
    }
  .laneIndicator {
    color: $white;
    position: absolute;
    top: 30px;
    height: 60px;
    width: 80px;
    margin: 0;
    padding: 0 5px;
    @include flex_center(column);
    @include mediaQuery-1024(){
      top: 10px;
    }
    p {
      margin: 0;
      line-height: 1;
      margin-top: 10px;
      text-align: center;
    }

    &.left {
      left: -85px;
    }

    &.right {
      right: -85px;
    }
  }

  .cardWrapper {
    position: relative;
    margin: 10px 3px;
    cursor: pointer;
    opacity: 0.8;

    &.scale {
      transform: scale(1.2);
      opacity: 1;
      z-index: 1;
    }
  }
}
