@import "../../../assets/styles/mixins";

.formWrapper {
  @include flex(column);
  padding: 3%;
  @include mediaQuery-767() {
    width: 100%;
  }

  .loginTitle {
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #00b2e7;
    margin: 15px 0;
    @include mediaQuery-641() {
      font-size: 35px;
    }
  }

  .inputWrapper {
    width: 100%;
    min-height: 90px;
    .keyIcon {
      color: #00b2e7;
      transform: rotate(-45deg);

      &fieldset {
        border-radius: 50px;
      }
    }

    .inputText {
      width: 100%;
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 1000px white inset !important;
    }
  }

  .buttonWrapper {
    position: relative;
    width: 100%;
    @include flex_center(row);
    margin: 10px 0;
    .button {
      width: 100%;
      padding: 15px 0;
      border-radius: 50px;
      background-image: linear-gradient(to right, #00b2e7, #04f795 147%);
      color: #ffffff;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.24;
    }

    .buttonProgress {
      position: absolute;

    }
  }
}

