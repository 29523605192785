@import "../../assets/styles/mixins";

.edit_profile_title {
  margin: 0.5rem;
  font-size: 20px;
  color: #505050;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;

  @include mediaQuery-481-max {
    font-size: 1.1rem;
  }
}

.password_change_container {
  margin-top: 2rem;
  width: 100%;
  h5 {
    align-self: flex-start;
    margin-bottom: 1rem;
    margin-left: 0.3rem;
  }
}
.password_change_input {
  label {
    margin: 0.5rem;
  }
  div {
    border-radius: 1.5625rem;
  }

}
.edit_profile_content, .password_change_container {
  @include flex_center(column);
}