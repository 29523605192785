@import 'assets/styles/mixins';
@import "assets/styles/style";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}


a {
  &:link, &:visited {
    text-decoration: none;
    color: currentColor;
  }

}

.active {
  background: linear-gradient(to right, #00b2e7 -56%, #04f795 144%);
  border-color: #04f795 !important;
  margin: 0;
}

.full-height-container {
  height: 100vh;

  header {
    position: fixed;
  }

  footer {
    position: fixed;
    bottom: 0;
  }
}
