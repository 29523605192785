@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.myResponseContainer {
  @include flex(row);
  min-height: 90vh;
  @include mediaQuery-1024() {
    flex-flow: column-reverse;
  }

  span {
    margin: 0;
  }

  p {
    margin: 0;
  }

  .title {
    display: flex;
    justify-content: center;
    margin: 10% 0;
    @include mediaQuery-1024() {
      margin: 4rem 0 1rem 0;
    }

    p {
      font-family: $type2;
      color: $white;
      font-size: 1.25rem;
      @include mediaQuery-min-768() {
        font-size: 1.8rem;
      }
      text-align: center;

      span {
        color: $primary-color;
        margin-right: 0.5vw;
      }
    }
  }

  .profileDescription {
    @include flex(column);
    margin: 0 10px;

    p {
      color: $white;
    }
  }

  .responseLeft {
    position: relative;
    flex-basis: 26%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding: 0 2vw;
    @include mediaQuery-1360() {
      flex-basis: 28%;
    }
    @include mediaQuery-1600() {
      flex-basis: 33%;
    }

    .commentContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .profile {
        @include flex_center(row);
        justify-content: flex-end;
        margin: 15px 0;


        img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }


      }

      span {
        color: $white;
        opacity: 0.5;
        margin-bottom: 15px;
      }

      .comment {
        position: relative;
        background-color: $primary-color;
        box-shadow: 0 23px 26px 0 rgba(0, 0, 0, 0.16);
        padding: 15px;
        border-radius: 5px 5px 0 5px;
        min-width: 200px;
        max-width: 255px;
        z-index: 2;
        &:before {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-color: transparent transparent transparent $primary-color;
          border-width: 20px 0 0 25px;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          left: 0;
          top: -15px;
        }

        &.positive {
          background-color: $secondary-color;

          &:before {
            border-color: transparent transparent transparent $secondary-color;
          }
        }

        &.negative {
          background-color: $red;

          &:before {
            border-color: transparent transparent transparent $red;

          }
        }

        h4 {
          font-size: 18px;
          font-weight: 900;
          line-height: 1.22;
          font-family: $type2;
          color: $text-dark;
          text-transform: uppercase;
        }

        p {
          color: $text-dark;
          font-size: 14px;
          font-weight: 500;
          font-family: $type2;
          max-height: 150px;
          overflow-y: scroll;
        }
      }
    }


  }

  .responseCenter {
    @include flex(column);
    align-items: center;
    flex-basis: 48%;
    @include mediaQuery-1360() {
      flex-basis: 44%;
    }
    @include mediaQuery-1600() {
      flex-basis: 34%;
    }
    flex-grow: 1;

    .backButton {
      border-radius: 50px;
      margin: 20px 0;
      width: 150px;
      height: 45px;
      background: transparent;
      border: solid 2px $secondary-color;
      color: $success-color;
    }
  }

  .responseRight {
    @include flex(column);
    align-self: flex-end;
    flex-basis: 26%;
    padding: 2vw;
    padding-left: 5%;
    @include mediaQuery-1024() {
      align-self: center;
      padding: 10px 0;
    }
    @include mediaQuery-1360() {
      flex-basis: 28%;
    }
    @include mediaQuery-1600() {
      flex-basis: 33%;
    }

    .myQuestionWrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      @include mediaQuery-1600() {
        width: 70%;
        margin-left: auto;
      }

      span {
        color: $white;
        opacity: 0.8;
        display: flex;
        align-self: flex-start;
        margin-left: 20px;
      }

      .question {
        position: relative;
        box-shadow: 0 23px 26px 0 rgba(0, 0, 0, 0.16);
        padding: 15px;
        border-radius: 5px 5px 0 5px;
        background-color: $primary-color;
        margin-top: 10px;
        width: 250px;
        &:after {
          //@include mediaQuery-1024() {
          //  display: none;
          //}
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 25px 20px 0;
          border-color: transparent $primary-color transparent transparent;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          bottom: -18px;
          right: 0;
        }

        .text{
          color: $white;
          max-height: 150px;
          overflow-y: scroll;
        }

      }

      .profile {
        @include flex_center(row);
        justify-content: flex-end;
        margin-top: 15px;
        align-self: flex-end;
        @include mediaQuery-1024() {
          justify-content: center;
        }

        img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }

        p {
          color: $white;
          opacity: 0.8;
        }
      }
    }

  }

}
