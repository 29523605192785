@mixin flex($direction) {
  display: flex;
  flex-flow: $direction;
}

@mixin flex_center($direction) {
  display: flex;
  flex-flow: $direction;
  justify-content: center;
  align-items: center;
}

@mixin formatted-text() {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}

/* smart-phones, iPhone, portrait 480x320 phones */
@mixin mediaQuery-320() {
  @media only screen and(min-width: 320px) {
    @content
  }
}
/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
@mixin mediaQuery-481() {
  @media only screen and(min-width: 481px) {
    @content
  }
}
/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@mixin mediaQuery-641() {
  @media only screen and(min-width: 641px) {
    @content
  }
}
/* tablet, landscape iPad, lo-res laptops ands desktops */
@mixin mediaQuery-961() {
  @media only screen and(min-width: 961px) {
    @content
  }
}
/* big landscape tablets, laptops, and desktops */
@mixin mediaQuery-1025() {
  @media only screen and(min-width: 1025px) {
    @content
  }
}
/* hi-res laptops and desktops */
@mixin mediaQuery-1281() {
  @media only screen and(min-width: 1281px) {
    @content
  }
}

@mixin mediaQuery-min-768() {
  @media only screen and(min-width: 768px) {
    @content
  }
}

@mixin mediaQuery-767() {
  @media only screen and(max-width: 767px) {
    @content
  }
}
@mixin mediaQuery-700-max-height-768-min-width() {
  @media only screen and(max-height: 700px) and (min-width: 768px){
    @content
  }
}
@mixin mediaQuery-1024() {
  @media only screen and(max-width: 1024px)  {
    @content
  }
}
@mixin mediaQuery-768-1024() {
  @media only screen and (min-width: 768px) and(max-width: 1024px) {
    @content
  }
}
@mixin mediaQuery-1024-1360() {
  @media only screen and (min-width: 1025px) and(max-width: 1360px) {
    @content
  }
}
@mixin mediaQuery-1360() {
  @media only screen and (min-width: 1361px)  {
    @content
  }
}
@mixin mediaQuery-1600() {
  @media only screen and (min-width: 1600px)  {
    @content
  }
}
@mixin mediaQuery-481-max() {
  @media only screen and(max-width: 481px) {
    @content
  }
}
