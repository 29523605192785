@import "../../../assets/styles/mixins";

.playInfo {
  //  background-image:  linear-gradient(to bottom, #00b2e7 50%, #04f795 250%);
  background-image: url("../../../assets/images/pattern1132.png");
  @include mediaQuery-1024-1360() {
    background-image: url("../../../assets/images/pattern1132@2x.png");
  }
  @include mediaQuery-1360() {
    background-image: url("../../../assets/images/pattern1132@3x.png");
  }
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: #fff;
  height: inherit;
  position: relative;
  @include flex_center(row wrap);


  .movingMotivator {
    position: absolute;
    padding: 0 10px;
    opacity: 1;
    z-index: 1;
    transition: opacity .6s;
    @include flex_center(column);
    @include mediaQuery-767(){
    opacity: 1 !important;
      position: relative;
      margin: 20px;
    }

    .logo {
      width: 120px;
      height: 60px;
      margin-bottom: 10px;
    }

    h2 {
      text-transform: uppercase;
      text-align: center;
      font-size: 1.7rem;
    }

    p:nth-child(3) {
      text-align: center;
      margin-top: 5px;
    }

    p:nth-child(4) {
      margin-top: 10px;
      text-align: center;
    }

    .moreButton {
      background: transparent;
      border-radius: 50px;
      color: #fff;
      border: 1px solid #fff;
      margin-top: 15px;
      padding: 10px 40px;
      @include mediaQuery-767() {
        display: none;
      }
    }
  }

  .howToPlay {
    position: absolute;
    opacity: 0;
    z-index: 0;
    padding: 0 15px;
    transition: opacity .6s;
    @include flex_center(column);
    @include mediaQuery-767(){
      opacity: 1 !important;
      position: relative;
      margin: 20px;

    }

    h1 {
      font-size: 1.8rem;
    }

    ul {
      //margin: 0 10px;

      li {
        display: flex;
        margin: 5px 0;
        font-size: 1rem;
        @include mediaQuery-1024(){
         font-size: 0.8rem;
        }
        line-height: 1.4;
        @include mediaQuery-1360() {
          line-height: 1.6
        }

        svg {
          margin: 0 5px;
        }
      }

    }

    .scrollUpButton {
      background: transparent;
      border-radius: 50px;
      color: #fff;
      border: 1px solid #fff;
      margin: 0;
      margin-top: 5px;
      padding: 5px 40px;
      @include mediaQuery-767() {
        display: none;
      }
    }

  }

}
