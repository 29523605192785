@import '../variables';
@import '../mixins';


.questions a {
  margin-bottom: 0.5em;
  display: block;
  &:last-child {
    margin-bottom: 0;
  }
}
.list {
  border-radius: 5px;
  background-color: rgba($white, 0.17);
  padding: 0.5em 1em;
  margin-bottom: 0.5em;
  color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  &:last-child {
    margin-bottom: 0;
  }

  &__meta {
    @include flex_center(row);
    width: 100%;
    justify-content: flex-start;
    a{
      @include flex(row);
      width: 100%;
      &.disabled{
        pointer-events: none;
      }
    }
    &--icon {
      object-fit: contain;
      margin-right: 0.5em;
    }

    &--icon-response {
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
    }

    &--title,
    &--date {
      @include formatted-text();
      font-family: $type2;
      margin-bottom: 0;
    }

    &--title {
      font-size: 14px;
      font-weight: normal;
      @include mediaQuery-641() {
        font-size: 18px;
      }
    }

    &--date {
      margin-left: 0.5em;
      font-size: 12px;
      font-style: italic;
      font-weight: lighter;
      @include mediaQuery-641() {
        font-size: 14px;
      }
    }
  }

  &__response-state {
    font-size: 12px;
    min-width: 105px;
    width: 105px;
    margin-left: 10px;

    &--check {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      border-radius: 50%;
      padding: 5px;
      background-color: #00e7a0;

      img {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__questions-state {
    display: flex;

    > div {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: solid 2px $white;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mediaQuery-641 {
        width: 50px;
        height: 50px;
      }
    }

    &--responded {
      margin-right: 5px;
      background-color: $secondary-color;
    }

    &--non-responded {
      background-color: $danger-color;
    }
  }
}
