:global {
  .autocomplete_form {
    margin-top: 0.5rem;
    width: 100%;

    .MuiAutocomplete-inputRoot {
      border-radius: 2rem;
    }

    .MuiFormLabel-root {
      &.Mui-focused {
        color: currentColor;
      }
    }

    .MuiAutocomplete-input {
      color: #fff;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }

    .MuiOutlinedInput-root {
      &:hover fieldset {
        border-color: #04f795;
      }


      &.Mui-focused fieldset {
        border-color: #fff;
      }
    }

    .MuiInputLabel-outlined,
    .Mui-focused,
    .MuiAutocomplete-popupIndicator,
    .MuiAutocomplete-endAdornment .MuiIconButton-label {
      color: #fff;
    }
  }
}

.MuiIcon-colorPrimary {
  color: #fff;
}




