.SlideIn-appear {
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);
  opacity: 0;

  &.SlideIn-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
}

.SlideIn-enter {
  opacity: 0;
  transform: translateX(30px);
  -webkit-transform: translateX(30px);
  -moz-transform: translateX(30px);
  -o-transform: translateX(30px);
  -ms-transform: translateX(30px);

  &.SlideIn-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.2s linear 0.4s;
  }
}

.SlideIn-leave {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);

  &.SlideIn-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(-30px);
    -webkit-transform: translateX(-30px);
    -moz-transform: translateX(-30px);
    -o-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transition: all 0.2s linear;
  }
}

.SlideOut-appear {
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  opacity: 0;

  &.SlideOut-appear-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.6s linear;
  }
}

.SlideOut-enter {
  opacity: 0;
  transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -o-transform: translateX(-30px);
  -ms-transform: translateX(-30px);

  &.SlideOut-enter-active {
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -o-transform: translateX(0);
    -ms-transform: translateX(0);
    transition: all 0.2s linear 0.4s;
  }
}

.SlideOut-leave {
  opacity: 1;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);

  &.SlideOut-leave-active {
    opacity: 0;
    position: absolute;
    width: 100%;
    transform: translateX(30px);
    -webkit-transform: translateX(30px);
    -moz-transform: translateX(30px);
    -o-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transition: all 0.2s linear;
  }
}

:global {
  .page {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .page-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      .container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .scroll-container {
          position: relative;
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .infinite-scroll {
            position: absolute;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            bottom: 0;
            top: 0;
            overflow-y: auto;
            padding-bottom: 0.5em;
          }
        }
      }
    }
  }
}