@import "src/assets/styles/mixins";

:global {
.MuiDialog-root {
  right: 2rem !important;
  @include mediaQuery-767 {
    margin: 0 auto;
    width: 90% !important;
    right: 0 !important;
  }
}
}

  .flexContainer {
    @include flex_center('row');
    width: 100%;
    margin-top: 2%;
  }

  .clearTop {
    margin-top: 1.5rem;
    margin-bottom: 2%;
  }

  .arrow_indicators__distance {
    margin: 4rem 0.2rem 0 0.2rem;
  }

