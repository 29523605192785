@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.questionContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @include mediaQuery-1024() {
    flex-flow: row-reverse wrap;
  }
  &.scroll{
    height: 50vh;
    overflow-y: scroll;
  }

  section {
    @include flex(row);

    &:first-child {
      @include flex_center(column);
      padding-left: 1vw;
      @include mediaQuery-1024() {
        flex-basis: 50%;
      }
      @include mediaQuery-1360() {
        margin-left: auto;
      }

      button {
        background: transparent;
        margin: 0;
      }
    }

    &:nth-child(2) {
      @include flex(column);
    }

    &:last-child {
      @include flex_center(column);
      padding-right: 1vw;
      @include mediaQuery-1024() {
        order: -1;
        flex-basis: 50%;
      }
      @include mediaQuery-1360() {
        margin-right: auto;
      }

      button {
        background: transparent;
        margin: 0;
      }
    }

    svg {
      border: 1px solid $white;
      border-radius: 50px;
      height: 50px;
      width: 50px;
      color: #fff;
      padding: 5px;
    }

    p {
      text-align: center;
      color: $white;
    }
  }

  .questionWrapper {
    @include flex(row wrap);
    width: 100%;
    justify-content: space-between;
    @include mediaQuery-1024() {
      justify-content: center;
    }

    .avatar {
      flex-basis: 15%;
      @include flex_center(column);
      align-items: flex-start;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        margin-bottom: 15px;
      }

      p {
        color: $white;
        margin: 0;
        font-size: 14px;
      }
    }

    .question {
      @include flex_center(row);
      flex-basis: 85%;
      background-color: rgba(255, 255, 255, 0.17);
      border-radius: 5px 5px 5px 0;
      @include mediaQuery-1024() {
        flex-basis: 100%;
        @include flex(row wrap);
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent transparent rgba(255, 255, 255, 0.17) transparent;
        border-width: 0 0 20px 25px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        left: -25px;
        margin-top: auto;
        margin-right: auto;
        @include mediaQuery-1024() {
          display: none;
        }
      }

      .questionLeft {
        @include flex(column);
        padding: 10px;
        flex-basis: 80%;

        p {
          text-align: left;
          margin: 0;
        }

        p:nth-child(2) {
          margin-top: 5px;
          font-size: 16px;
          opacity: 0.7;
        }
      }

      .questionRight {
        @include flex(column);
        padding: 20px;
        flex-basis: 20%;

        p {
          span {
            font-weight: bold;
            font-size: 2rem;
          }
        }
      }
    }
  }
}

