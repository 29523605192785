@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/fonts";

.filterBarWrapper {
  color: #ffffff;
  display: flex;
  margin: 2vh 0;
  margin-top: 5vh;
  @include mediaQuery-767() {
    flex-flow: column;
  }

  .title {
    flex-basis: 45%;
  }

  .filters {
    @include flex(row wrap);
    flex-basis: 55%;
    align-items: center;
    justify-content: flex-end;
    @include mediaQuery-481-max() {
      flex-flow: column;
    }

    p {
      margin: 0;
      margin-right: 5px;
    }

    .filterButton {
      background: transparent;
      border-radius: 50px;
      font-size: 14px;
      font-family: "Lato", "Roboto", "Arial", sans-serif;
      color: #fff;
      border: 1px solid #fff;
      padding: 5px 20px;
      margin: 0 5px;

      &.active {
        background: #ffffff;
        color: #00bae7;
      }
    }
    &.fullWidth{
      .filterButton{
        width: 100%;
      }
    }
  }
}
