@import "../../assets/styles/variables";

.appBarWrapper {
  &:global(.MuiAppBar-colorPrimary) {
    background: rgba($white, 0.1);
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
    height: 7vh;
    color: $white;
    padding: 0 20px !important;

    .profileContainer {
      display: flex;
      align-items: center;
    }

    .loginBtn {
      border: 1.5px solid $white;
      padding: 5px 20px;
      border-radius: 100px;
      text-transform: uppercase;
    }

    .headerTitle {
      .headerLink {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:link, &:visited {
          text-decoration: none;
          color: currentColor;
        }

        img {
          width: 40px;
          height: 40px;
          margin-right: 5px;
        }
      }


    }

    .headerIcon {
      padding: 0 !important;
      color: $white;
      &:hover {
        background: transparent !important;
      }
    }

    .icon {
      font-size: 40px;
      margin-right: 5px;
    }
  }
}





