@import "../../assets/styles/mixins";

.no_items_text {
  font-size: 1rem;
  @include mediaQuery-961() {
    font-size: 1.5rem;
  }
  @include mediaQuery-1281() {
    font-size: 2rem;
  }
}
