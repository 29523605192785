


.message_container{
  @include flex_center(row);
  width: 100%;
  border:2px dashed $white;

  .message_body{
    color: $white;
    font-size: 1.2rem;
  }
}
