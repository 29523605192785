@import "../../assets/styles/mixins";
@import "../../assets/styles/variables";

.questionResponseContainer {
  @include flex(column);
  flex-wrap: wrap;
  justify-content: center;

  .verticalCardWrapperContainer {
    @include flex(column);
    align-items: center;
    margin: 0 auto;

    .sortingHintContainer {
      margin-bottom: 1.5rem;
    }

  }

  .verticalCardWrapper {
    display: inherit;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: auto;
    height: 600px;
    width: 20rem;
    border-radius: 5px;
    border: 1px solid $primary-color;
    padding: 3rem;
    text-align: center;

    &::-webkit-scrollbar {
      display: none;
    }

    .cardContainer {
      margin: 1rem;
    }
  }

  .buttonsContainer {
    @include flex(column);
    margin-top: 0.8rem;
    align-items: center;
    justify-content: center;

    button {
      margin: 5px auto;
    }
  }

  .loadingContainer {
    display: flex;
    justify-content: center;
  }

  .navigationCardsWrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 3vh;
    @media only screen and (min-height: 800px) and(min-width: 1600px) {
      margin-top: 100px;
    }

    .cardContainer {
      @include flex_center(row wrap);
      flex-basis: 40%;
      @include mediaQuery-1024-1360() {
        flex-basis: 45%;
      }
      @include mediaQuery-1600() {
        flex-basis: 35%;
      }

      .slotContainer {
        position: relative;

        .laneIndicator {
          color: $white;
          position: absolute;
          top: 30px;
          height: 60px;
          width: 80px;
          margin: 0;
          padding: 0 5px;
          @include flex_center(column);
          @include mediaQuery-1024() {
            top: 10px;
          }

          p {
            margin: 0;
            line-height: 1;
            margin-top: 10px;
            text-align: center;
          }

          &.left {
            left: -85px;
          }

          &.right {
            right: -85px;
          }
        }
      }

      .cardWrapper {
        @include flex_center(row);
        border-radius: 10px;
        border: dashed 2px rgba(255, 255, 255, 0.45);
        margin: 1vh 1.5vh;
        width: 110px;
        height: 115px;

        p {
          font-size: 2.5rem;
          color: rgba(255, 255, 255, 0.45);
          width: 50px;
          text-align: center;
        }
      }
    }

    .navigationButtons {
      border-radius: 50px;
      margin: 0;
      width: 150px;
      height: 45px;

      &:disabled {
        background: rgb(95, 98, 107);
      }

      &.cancelButton {
        background: transparent;
        border: solid 2px $secondary-color;
        color: $success-color;
      }
    }
  }

  .draggableCardsSection {
    @include flex(row);
    justify-content: center;
  }
}
