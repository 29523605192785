@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";
.respondentsContainer {
  overflow-y: scroll;
  height: 30vh;
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  position: relative;
  .respondent {
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
    align-items: center;
    width: 255px;
    height: 70px;
    border-radius: 5px;
    border: solid 1px rgba(255, 255, 255, 0.28);
    background-color: rgba(255, 255, 255, 0.17);
    margin: 0 1vh;
    margin-bottom: 3vh;
    position: relative;
    &.fullWidth{
      width: 100%;
    }
    &.disabled{
      pointer-events: none;
    }
    img {
      border-radius: 50px;
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

    .cardInfo {
      @include flex(column);
      color: #ffffff;

      p {
        font-family: 'Montserrat', sans-serif;
        text-align: start;
      }

      span {
        font-family: "Lato", "Roboto", "Arial", sans-serif;
        font-style: italic;
      }

    }
    svg{
      width: 25px;
      height: 25px;
      color: #ffffff;
      position: absolute;
      left: -10px;
      top: -10px;
    }
    .responded{
      border: solid 2px #00e7a0;
      background-color: #00e7a0;
    }
    .pending{
      border: solid 2px #dd5c5c;
      background-color: #dd5c5c;
    }
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #4cfff3;
  }

}
