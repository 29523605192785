@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.Container {
  @include flex(column);
  align-items: center;

  .cardsContainer {
    display: flex;
    flex-direction: column;

    .cardWrapper {
      display: flex;
      flex-flow: column;
      justify-content: center;
      margin: 5px 0;
      right: 25px;

      .imgWrapper {
        position: relative;
        width: 290px;
        height: 55px;

        img {
          position: absolute;
          width: inherit;
          clip: rect(0px, auto, 55px, 0px);

        }

        .commentIcon {
          position: absolute;
          z-index: 1;
          right: 4px;
          top: 5px;
          width: 25px;
          height: 25px;
        }
      }

      .cardControl {
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 75px;
        background: $dark-background-2;

        .commentIcon {
          height: 100%;
          padding: 0 5px;
          width: 42px;
          background: $dark-background-3;
          margin-right: -45px;
        }
      }

    }

  }
}

