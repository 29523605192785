@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.finalStepContainer {
  height: 90vh;
  overflow-y: scroll;
  max-height: unset;
}

.loadingContainer {
  display: flex;
  justify-content: center;
}

.leftBlock {
  flex-basis: 22%;
  @include mediaQuery-1600() {
    flex-basis: 35%;
  }
}

.centerBlock {
  flex-basis: 58%;
  @include mediaQuery-1600() {
    flex-basis: 30%;
  }

  .cardsContainer {
    margin-top: 1.9rem;

    .dragDropContainer {
      display: flex;
      flex-flow: column;
      align-items: center;
      min-width: 740px;
      max-width: 1000px;
    }

    .negative_changes, .positive_changes, .cards_lane {
      @include flex(row);
      justify-content: flex-start;
      width: 100%;
      height: 120px;
    }

    .cardWrapper {
      position: relative;

      &.animateCard {
        animation: cardAnimation 0.8s 1 ease-in-out;
      }

      .cardComment {
        position: absolute;
        @include flex_center(row);
        width: 3.8rem;
        left: 0.25rem;
        bottom: 0.13rem;
        height: 3.75rem;
        z-index: 1;
        &.positiveHighlighted {
          background: $secondary-color-see-through;
        }

        &.negativeHighlighted {
          background: $danger-color-see-through;
        }


        img {
          width: 1.875rem;
          height: 1.875rem;
        }
      }
    }

    .negative_changes {
      border: 3px dashed $tomato_red;
      border-radius: 1rem;
      padding: 1rem;
      padding-top: 1.5rem;
      position: relative;

      .negative_label {
        background: $tomato_red;
        color: $white;
      }

      &.isDraggingOver {
        background: $danger-color-see-through;
      }
    }

    .lane_label {
      position: absolute;
      padding: 0.8rem 0;
      width: 20%;
      left: 40%;
      top: -22.5%;
      border-radius: 4rem;
    }

    .cards_lane {
      display: flex;
      justify-content: center;
      border: 3px dashed $pale-blue;
      position: relative;
      border-radius: 1rem;
      padding: 1rem;
      padding-top: 1.5rem;

      .laneIndicator {
        color: $white;
        position: absolute;
        top: 30px;
        height: 60px;
        width: 80px;
        margin: 0;
        padding: 0 5px;
        @include flex_center(column);

        p {
          margin: 0;
          line-height: 1;
          margin-top: 10px;
          text-align: center;
        }

        &.left {
          left: -100px;

        }

        &.right {
          right: -100px;
        }
      }
    }

    .positive_changes {
      border: 3px dashed $secondary-color;
      border-radius: 1rem;
      padding: 1rem;
      padding-top: 1.5rem;
      position: relative;

      .positive_label {
        background: $secondary-color;
        color: $black;
      }

      &.isDraggingOver {
        background: $secondary-color-see-through;
      }
    }


  }
}

.rightBlock {
  @include flex(column);
  align-self: flex-end;
  flex-basis: 22%;
  padding-bottom: 3.13rem;
  @include mediaQuery-1600() {
    flex-basis: 35%;
  }


  .comment {
    position: relative;
    box-shadow: 0 23px 26px 0 rgba(0, 0, 0, 0.16);
    padding: 15px;
    border-radius: 5px 5px 0 5px;
    background-color: $primary-color;
    width: 220px;
    margin-left: auto;


    span {
      position: absolute;
      top: -25px;
      left: 0;
      color: $white;
      opacity: 0.5;
      margin-bottom: 15px;

    }

    //@include mediaQuery-1600() {
    //  width: 40%;
    //  margin-left: auto;
    //}

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 25px 20px 0;
      border-color: transparent $primary-color transparent transparent;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      bottom: -20px;
      right: 0;
    }

    &.positive {
      background-color: $secondary-color;
      color: $dark-color;

      &:after {
        border-color: transparent $secondary-color transparent transparent;
      }

      h4 {
        color: $dark-color;
      }
    }

    &.negative {
      background-color: $tomato_red;
      color: $white;

      &:after {
        border-color: transparent $tomato_red transparent transparent;

      }

      h4 {
        color: $white;
      }
    }

    .commentHeader {
      display: flex;
      justify-content: space-between;

      h4 {
        font-size: 18px;
        font-weight: 900;
        line-height: 1.22;
        font-family: $type2;
        text-transform: uppercase;
      }

    }


  }


  .profile {
    @include flex_center(row);
    justify-content: flex-end;
    margin-top: 15px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
    }

    p {
      margin: 0 10px;
      color: $white;
    }
  }
}


.buttons_wrapper {
  @include flex-center(row);
  flex-wrap: wrap;
  padding: 0.8rem;

  > button {
    margin: 1rem;
    border-radius: 4rem;
    padding: 0.5rem 2rem;
  }
}

.confirmation_wrapper {
  @include flex(row);
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 1rem;

  p {
    color: $white;
    margin-top: -2rem;
  }
}

.dummyCard {
  width: 3.75rem;
  height: 4.38rem;
  margin: 0 0.3rem;
}

@keyframes cardAnimation {
  0% {
    transform: scale(1.2)
  }
  25% {
    transform: scale(1)
  }
  75% {
    transform: scale(1.2)
  }
  100% {
    transform: scale(1)
  }
}
