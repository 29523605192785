@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

body,
html {
  overflow-x: hidden;
  background: $primary-color;
}

*,
:before,
:after {
  box-sizing: border-box;
}


body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-color;
}

/*---------------------
  Helper CSS
-----------------------*/
.container {
  padding-right: 15px !important;
  padding-left: 15px !important;
  margin-right: auto;
  margin-left: auto;
  @include mediaQuery-961() {
    width: 750px;
  }
  @include mediaQuery-1025() {
    width: 970px;
  }
  @include mediaQuery-1281() {
    width: 1170px;
  }
}

.title {
  @include formatted-text();
  color: $white;
  font-family: $type2;
  font-size: 1.2rem;
  text-transform: capitalize;
  font-weight: 300;
  word-spacing: 0.2rem;
  margin: 1rem;
  @include mediaQuery-641() {
    font-size: 1.5rem;
  }
  @include mediaQuery-1281() {
    font-size: 2.3rem;
  }
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-white {
  color: $white !important;
}

.pointer {
  cursor: pointer;
}
