@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";

.myResponseContainer {
  @include flex(row);
  min-height: 90vh;
  @include mediaQuery-1024() {
    flex-flow: column;
  }

  .title {
    display: flex;
    justify-content: center;
    margin: 10% 0;
    @include mediaQuery-1024() {
      margin: 1% 0;
    }

    p {
      font-family: $type2;
      color: $white;
      font-size: 1.8rem;
      text-align: center;

      span {
        color: $primary-color;
        margin-right: 0.5vw;
      }
    }
  }

  .responseLeft {
    position: relative;
    flex-basis: 26%;
    @include mediaQuery-1360() {
      flex-basis: 28%;
    }
    @include mediaQuery-1600() {
      flex-basis: 33%;
    }
  }

  .responseCenter {
    @include flex(column);
    align-items: center;
    flex-grow: 1;
    flex-basis: 48%;
    @include mediaQuery-1360() {
      flex-basis: 44%;
    }
    @include mediaQuery-1600() {
      flex-basis: 33%;
    }

    .backButton {
      border-radius: 50px;
      margin: 20px 0;
      width: 150px;
      height: 45px;
      background: transparent;
      border: solid 2px $secondary-color;
      color: $success-color;
    }
  }

  .responseRight {
    @include flex(column);
    align-self: flex-end;
    flex-basis: 26%;
    padding:  2vw;

    @include mediaQuery-1360() {
      flex-basis: 28%;
    }
    @include mediaQuery-1600() {
      flex-basis: 33%;
    }


    .commentContainer {
      @include mediaQuery-1600() {
        width: 70%;
        margin-left: auto;
      }

      span {
        color: $white;
        opacity: 0.5;
      }

      .comment {
        position: relative;
        box-shadow: 0 23px 26px 0 rgba(0, 0, 0, 0.16);
        padding: 15px;
        border-radius: 5px 5px 0 5px;
        margin-top: 10px;
        background-color: $primary-color;


        &:after {
          content: '';
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 25px 20px 0;
          border-color: transparent $primary-color transparent transparent;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          bottom: -18px;
          right: 0;
        }

        &.positive {
          background-color: $secondary-color;

          &:after {
            border-color: transparent $secondary-color transparent transparent;
          }
        }

        &.negative {
          background-color: $red;

          &:after {
            border-color: transparent $red transparent transparent;

          }
        }

        h4 {
          font-size: 18px;
          font-weight: 900;
          line-height: 1.22;
          font-family: $type2;
          color: $text-dark;
          text-transform: uppercase;
        }

        p {
          color: $text-dark;
          font-size: 14px;
          font-weight: 500;
          font-family: $type2;
          max-height: 150px;
          overflow-y: scroll;
        }

      }

      .profile {
        @include flex_center(row);
        justify-content: flex-end;
        margin-top: 15px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50px;
        }

        p {
          margin: 0 10px;
          color: $white;
        }
      }
    }

  }

}
