@import "../../assets/styles/mixins";

.loginContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  @include mediaQuery-641() {
    padding: 18vh 5vw;
  }
  @include mediaQuery-700-max-height-768-min-width() {
    padding: 10vh 5vw;
  }

  .loginWrapper {
    @include flex(row);
    border-radius: 20px;
    z-index: 2;
    background: #ffffff;
    justify-content: space-around;
    box-shadow: 0 0 66px 0 rgba(0, 0, 0, 0.25);
    width: 100%;
    > * {
      flex-basis: 50%;
    }

    @include mediaQuery-767() {
      @include flex_center(column-reverse);
      height: auto;
      > * {
        width: 100%;
      }
    }
  }
}
