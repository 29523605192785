@import '../../assets/styles/mixins';
@import "../../assets/styles/variables";
@use "sass:map";

.container {
  display: flex;
  background: transparent;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  margin: 0;
  width: 100vw;
  padding: 1rem;

  .title {
    color: #fff;
    align-self: center;
    text-align: center;
    text-transform: capitalize;
    font-family: $type2;
    font-size: 1.5rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    word-spacing: 0.2rem;
    margin: 1rem;

    @include mediaQuery-320() {
      font-size: 1.2rem;
    }

    @include mediaQuery-481() {
      font-size: 2rem;
    }

    @include mediaQuery-641() {
      font-size: 2.3rem;
    }
  }
  .error_message {
    color: #ff0000;
    align-self: flex-start;
    margin-left: 1rem;
  }

  .question__form {
    display: inherit;
    width: 100%;
    flex-direction: column;
    margin-top: 2rem;
    .question_form_button_container {
      @include flex_center(row);
    }
    .question__form__button {
      text-align: center;
      width: 50%;
      margin-top: 1rem;
      align-self: flex-end;
      padding: 0.8rem;
      border-radius: 2rem;
      background: map_get($theme-gradient-colors, 'primary');
      &:hover {
        background: map_get($theme-gradient-colors, 'info');
      }
      @include mediaQuery-641() {
        padding: 0.6rem;
        span {
          font-size: 0.8rem;
        }
      }
    }
  }

  .success_message {
    @include flex_center('column');
    flex-wrap: wrap;
    width: 70%;
    text-align: center;
    margin: 0 auto;
    .success_message_iconContainer {
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
    .success_message_body {
      margin: 1rem;
      width: 80%;
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: center;
    }
    .success_message_button {
      background: map_get($theme-gradient-colors, 'primary');
      border-radius: 1rem;
      width: 100%;
      padding: 0.5rem;
      &:hover {
        background: map_get($theme-gradient-colors, 'info');
      }
    }

  }
}



