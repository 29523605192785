@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.navContainer {
  background-color: transparent;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
  padding: 40px 0;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: linear-gradient(to right, $primary-color -56%, $secondary-color 144%) !important;
  height: 100% !important;

  .link {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0;
    background-color: transparent;
    height: 50px;
    border: 0;

    .icon {
      margin-right: 5px;
    }

    span.shared {
      padding: 0;
    }

    @include mediaQuery-961() {
      justify-content: center;
    }
  }

  .navBtn {
    border-radius: 0 !important;
    width: 100% !important;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

}

.iconContainer {
  background: transparent !important;
}

span {
  &.shared {
    text-transform: capitalize;
    margin-right: 5px;
    @include mediaQuery-961() {
      margin-right: 0;
    }
  }

  &.small {
    opacity: 0.8;
    font-size: 14px;
  }

  &.big {
    font-size: 20px;
  }
}

.icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-bottom: 5px;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  border: 2px solid $white;
  border-radius: 50%;
}

.navBtn {
  border-radius: 50% !important;
  padding: 0 !important;
  background-image: none !important;
}
