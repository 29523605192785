@import '../../../assets/styles/mixins';
@import "../../../assets/styles/variables";

.questionWrapper {
  position: relative;
  &.absolutePosition{
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 2;
  }
  width: 250px;
  @include flex(column);
  margin: 3vh;
  @media screen and (max-width: 1025px) {
    margin: 1rem auto;
    position: static;
  }

  .questioner {
    @include flex(row);
    margin-bottom: 3vh;

    img {
      width: 60px;
      height: 60px;
      border-radius: 50px;
      margin-right: 10px;
    }

    .questionerDescription {
      @include flex(column);
      justify-content: center;

      p {
        white-space: nowrap;
        height: 18px;
        font-family: $type3;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: $white;
        width: 100%;
      }

      span {
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: italic;
        font-family: $type2;
        letter-spacing: normal;
        text-align: left;
        color: $white;
      }
    }

  }

  .question {
    box-shadow: 0 23px 26px 0 rgba(0, 0, 0, 0.16);
    padding: 10px 15px;
    border-radius: 5px;
    position: relative;
    color: $white;
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-color: transparent transparent transparent $primary-color;
      border-width: 20px 0 0 25px;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      left: 0;
      top: -15px;
    }

    &.primary {
      background-color: $primary-color;

      &:before {
        border-color: transparent transparent transparent $primary-color;
      }
    }

    &.secondary {
      background-color: $secondary-color;

      &:before {
        border-color: transparent transparent transparent $secondary-color;
      }

      p {
        color: $dark-color;
        margin: 0;
      }

    }
    .text {
      overflow-y: auto;
      max-height: 150px;
    }
  }
}
