@import "../variables";
@import "../mixins";

.btn {
  font-weight: bold;
}

.btn {
  padding: 0.5em 1em;
  border: none;
  font-family: Lato, serif;
  background: none;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  outline: none;
  transition: all 0.3s ease-in-out;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  border-radius: 20px;

  @include mediaQuery-961() {
    padding: 0.5em 1.5em;
  }

  &.btn-primary {
    background-image: linear-gradient(to right, $primary-color -45%, $secondary-color 109%);
    color: white;

    &:hover {
      background-image: linear-gradient(to right, $secondary-color -45%, $primary-color 109%);
      color: $white;
    }
  }

  &.btn-outlined {
    border-radius: 60px;
    background-color: transparent;
    color: $white;
    text-transform: none;
    padding: 0.4em 1.5em;
    box-shadow: 0 13px 26px 0 rgba(0, 186, 231, 0.17);
    border: solid 2px #ededed;
  }
}

/* Sizes */
.btn--size-s {
  font-size: 14px;
  @media (max-width: 320px) {
    font-size: 12px;
  }
}

.btn--size-m {
  font-size: 16px;
}

.btn--size-l {
  font-size: 18px;
}

/* Typography */
.btn--text-upper {
  letter-spacing: 2px;
  text-transform: uppercase;
}

.btn--text-thin {
  font-weight: 300;
}

.btn--text-medium {
  font-weight: 500;
}

.btn--text-thick {
  font-weight: 600;
}

/* Roundedness */
.btn--round-s {
  border-radius: 5px;
}

.btn--round-m {
  border-radius: 15px;
}

.btn--round-l {
  border-radius: 40px;
}

/* Borders */
.btn--border-thin {
  border: 1px solid;
}

.btn--border-medium {
  border: 2px solid white;
}

.btn--border-thick {
  border: 3px solid;
}

/*Width*/
.btn--width-l {
  width: 100%
}






