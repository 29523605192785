@import "assets/styles/variables";

body {
  margin: 0;

}
.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  font-family: "Roboto", sans-serif;
  overflow-y: hidden;

  &.allowScroll{
    max-height: none;
  }
  &.lightBackground{
    background-image: linear-gradient(to right, #00b2e7 56%, #04f795 144%) ;
    background-repeat: no-repeat;
  }
  &.darkBackground{
    background-color: $dark-background;
    background-repeat: no-repeat;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  &--not-logged-in {
    justify-content: flex-end;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba($white, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba($white, 0.3);
  background-color: rgba($black, 0.4);
}


