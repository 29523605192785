////////// COLOR SYSTEM //////////

$blue: #2daee1;
$indigo: #6610f2;
$purple: #6a008a;
$pink: #E91E63;
$red: #f96868;
$roman: #dd5c5c;
$orange: #f2a654;
$yellow: #f6e84e;
$green: #46c35f;
$teal: #58d8a3;
$cyan: #57c7d4;
$black: #000;
$white: #ffffff;
$white-smoke: #f2f7f8;
$violet: #41478a;
$darkslategray: #2e383e;
$dodger-blue: #3498db;
$pale-blue:#024c62;
$dark-background:#003545;
$dark-background-2 :#006482;
$dark-background-3:#01536c;
$colors: (
        blue: $blue,
        indigo: $indigo,
        purple: $purple,
        pink: $pink,
        red: $red,
        orange: $orange,
        yellow: $yellow,
        green: $green,
        teal: $teal,
        cyan: $cyan,
        white: $white,
        gray: #434a54,
        gray-light: #aab2bd,
        gray-lighter: #f2f3f4,
        gray-lightest: #e6e9ed,
        gray-dark: #0f1531,
        black: #000000
);


$primary-color: #00b2e7;
$secondary-color: #04f795;
$secondary-color-see-through:rgba(4, 247, 149, 0.34);
$secondary-color-darker: darken(#00b2e7, 30%);
$success-color: #00d663;
$info-color: #00b3ff;
$warning-color: #ffe671;
$danger-color: #dd5c5c;
$tomato_red:#f02a2a;
$danger-color-see-through:rgba(249, 104, 104, 0.34);
$light-color: #506690;
$dark-color: #000000;
$dark-color-see-through: rgba(0, 0, 0, 0.34);
$theme-gradient-colors: (
        primary: linear-gradient(to right, #00b2e7 -56%, #04f795 144%),
        secondary: linear-gradient(to right, #e7ebf0, #868e96),
        success: linear-gradient(45deg, #7bffce, #30c93e),
        info: linear-gradient(to right, #60ea90 -56%, #53cc7c 144%),
        warning: linear-gradient(135deg, #ffc480, #ff763b),
        danger: linear-gradient(316deg, #fc5286, #fbaaa2),
        light: linear-gradient(to right, #cfd9df 0%, #e2ebf0 100%),
        dark: linear-gradient(to right, #7d7979 0%, #000000 100%)
);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #fff;
$border-color: #f1efef;

////////// COLOR VARIABLES //////////


////////// SOCIAL COLORS //////////

$social-colors: (
        twitter: #2caae1,
        facebook: #3b579d,
        google: #dc4a38,
        linkedin: #0177b5,
        pinterest: #cc2127,
        youtube: #e52d27,
        github: #333333,
        behance: #1769ff,
        dribbble: #ea4c89,
        reddit: #ff4500
);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1: 'Roboto', sans-serif;
$type2: 'Lato', sans-serif;
$type3: 'Montserrat, sans-serif';
$default-font-size: 0.937rem; // 14px as base font size

$text-muted: #a7afb7;
$text-dark: #505050;
$body-color: #6c7293;

$toggle-switch-bg: #ccc;


/////BUTTONS/////
$btn-border-radius: 50px;
$btn-font-size: 0.875rem;
$btn-padding-y: 11px;
$btn-padding-x: 30px;
/////BUTTONS/////

$font-weight-bold: 600;
$grid-gutter-width: 28px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;


$input-padding-y: 1.25rem;
$input-padding-x: 1.25rem;
$input-placeholder-color: #919aa3;
$input-font-size: 14px;

$footer-color: #e5e5e5;
$footer-bottom-bg: rgba(255, 255, 255, .07);
$footer-border: rgba(151, 151, 151, .07);
$owl-overlay-bg: rgba(60, 55, 241, .8);

$heading-color: white;

$hl-color: #2196F3;
$trans-time: 300ms;
$width: 320px;
$muted-color: mix(white, $primary-color, 70%);

$footer-height: 80px;
$small-header-height: 43px;

